import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from './services/app.service';
import {MainComponent} from './core/main.component';
import {NavigationService} from './services/navigation.service';
import {KiosksModelService} from './models/kiosks.model.service';
import {AuthService} from './services/auth.service';
import {LegalPersonsModelService} from "./models/legal-persons.model.service";
import {HistoryModelService} from "./models/history.model.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends MainComponent implements OnInit, OnDestroy {
  kiosksModelService: KiosksModelService;
  legalPersonsModelService: LegalPersonsModelService;
  historyModelService: HistoryModelService;

  loadStepCount = 0;

  constructor(
    appService: AppService,
    navigationService: NavigationService,
    kiosksModelService: KiosksModelService,
    private authService: AuthService,
    legalPersonsModelService: LegalPersonsModelService,
    historyModelService: HistoryModelService,
  ) {
    super(appService, navigationService);
    this.kiosksModelService = kiosksModelService;
    this.legalPersonsModelService = legalPersonsModelService;
    this.historyModelService = historyModelService;
    this.initializeApp();
  }

  async initializeApp() {
    await this.kiosksModelService.getKiosks();
    // Если getKiosks выполнен успешно, продолжаем загрузку
    if (this.kiosksModelService.kiosksLoaded) {
      this.loadData();
      this.appService.appReady = true;
    }
    // если стартовая страница не киоск, то обнуляем scrollPosition и lastKioskId
    if (!this.navigationService.currentUrl.includes('kiosk')) {
      localStorage.removeItem('scrollPosition');
      localStorage.removeItem('lastKioskId');
    }
  }

  loadData() {
    if (this.authService.isLogin()) {
      this.kiosksModelService.startHeartBeat();
      this.legalPersonsModelService.getLegalPersons();
      this.legalPersonsModelService.getShortsLegalPersons();
    }
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
