import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {ApiResponse} from "../entities/apiResponse.entity";
import {Kiosk, KioskFilterItem} from "../entities/kiosk.entity";
import {MainModelService} from "../core/mainModel.service";
import {CommandsModelService} from "./commands.model.service";
import {forkJoin} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class KiosksModelService extends MainModelService {
  kiosksItems: Kiosk[];
  kiosksItemsToShow: Kiosk[];
  kiosksVersions: KioskFilterItem[];
  versions = [];
  kiosksLoaded = false;
  private timer: any = null;
  private delay = 1000 * 30;
  constructor(
    http: HttpClient,
    appService: AppService,
    private commandsModelService: CommandsModelService,
    private router: Router
  ) {
    super(http, appService);

    this.model = 'kiosk';
  }

  startHeartBeat() {
    this.timer = setInterval(()=> {
      if (this.appService.heartBeatOnPause || this.commandsModelService.kiosksSelected.length) {
        return
      }
      this.getKiosks();
    }, this.delay);
  }

  async stopHeartBeat() {
    clearInterval(this.timer);
  }

  async getKiosks() {
    return new Promise<void>((resolve, reject) => {
      const getData: any = {};
      this.getRequest(getData, async (data: ApiResponse) => {
        if (data) {
          await this.updateKioskStatuses(data.result); // Update statuses
          this.kiosksLoaded = true;
          resolve(); // Резолвим промис после обновления данных
        } else {
          reject(new Error('No data received')); // Отклоняем промис в случае ошибки
        }
      }, 'heartBeat/', false, true);
    });
  }

  async getVersions() {
    return new Promise<void>((resolve, reject) => {
      const getData: any = {};
      this.getRequest(getData, (data: ApiResponse) => {
        // console.log('getVersions data', data);
        if (data.status) {
          this.versions = data.result;
          // console.log('this.versions', this.versions);
          // this.updateKioskStatuses(data.result); // Update statuses
          resolve(); // Резолвим промис после обновления данных
        } else {
          reject(new Error('No data received')); // Отклоняем промис в случае ошибки
        }
      }, 'getVersions/', false, false);
    });
  }

  async updateKioskStatuses(items) {
    const kiosksVersions = [];
    const versionMap = new Map<string, number>();
    if (items) {
      items.forEach((item) => {
        item.status = this.getStatus(item); // Set status based on last_sync
        item.checked = false;
        item.kiosk_name = item.settings.kiosk_name;
        item.kiosk_address = item.settings.kiosk_address;
        item.date_closed = item.settings.date_closed?.length ? this.formatLastSync(item.settings.date_closed, true) : ''; // item.settings.date_closed;
        item.date_opened = item.settings.date_opened?.length ? this.formatLastSync(item.settings.date_opened, true) : ''; // item.settings.date_opened;
        item.is_closed = item.settings.is_closed;
        item.last_sync = this.formatLastSync(item.last_sync);
        item.warehouse_code = item.settings.warehouse_code;
        item.company_id = item.settings?.company_id?.toString();
        item.terminal_tid = item.settings?.terminal_tid ? item.settings?.terminal_tid : '';
        item.router = item.settings?.router ? item.settings?.router : '';
        item.payment_info = item.settings?.payment_info ? item.settings?.payment_info : '';
        item.anydesk = item.settings?.anydesk;
        if (!versionMap.has(item.config.version)) {
          // If version is not in the map, add it to kiosksVersions
          const id = kiosksVersions.length + 1; // You might need a better way to generate IDs
          kiosksVersions.push({ id: id.toString(), name: item.config.version });
          versionMap.set(item.config.version, id);
        }
      });

      const mode = this.router.url.slice(1);
      // console.log('mode', mode);
      // Применить разную сортировку в зависимости от страницы
      if (mode === 'closed') {
        // Сортировка по дате закрытия
        items.sort((a, b) => {
          if (a.date_closed && b.date_closed) {
            const dateA = new Date(a.date_closed.split('.').reverse().join('-'));
            const dateB = new Date(b.date_closed.split('.').reverse().join('-'));
            return dateB.getTime() - dateA.getTime();
          } else if (a.date_closed) {
            return -1;
          } else if (b.date_closed) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        // Сортировка по статусу
        items.sort((a, b) => {
          const statusOrder = ['Error', 'Test terminal', 'Test mode', 'Blocked', 'Offline', 'Online'];
          return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        });
      }

      this.kiosksItems = await items.filter((item) => item.is_closed === (mode === 'closed' ? '1' : null));
      this.kiosksItemsToShow = await items.filter((item) => item.is_closed === (mode === 'closed' ? '1' : null));
      this.kiosksVersions = await kiosksVersions;
      // console.log('this.kiosksItems', this.kiosksItems);
      // console.log('this.kiosksItemsToShow', this.kiosksItemsToShow);
    }
  }

  formatLastSync(value: string, dateOnly = false): string {
    // Парсим строку даты в объект Date
    const date = new Date(value);

    // Форматируем день, месяц и год
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Форматируем часы, минуты и секунды
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Собираем отформатированную строку даты
    let formattedDate = '';
    if (dateOnly) {
      formattedDate = `${day}.${month}.${year}`;
    } else {
      formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }

    return formattedDate;
  }

  getStatus(item: Kiosk) {
    const timeOffset = (-180 - (new Date().getTimezoneOffset())) * 60 * 1000;
    const time = item.last_sync.replace(/-/gi, '/');

    const last = new Date(time).getTime() + timeOffset;
    const now = new Date().getTime();


    const between = (now - last) / 1000 / 60;
    let status = 'Online';

    const device = item.config.device;

    if (between > 5) {
      status = 'Offline';
    }
    if (!device || !['GateUCS', 'GateSbRf', 'GateArcus2'].includes(device.IdTypeDevice)) {
      status = 'Error';
    }
    if (item.config.testMode) {
      status = 'Test mode';
    }
    if (device && device.IdTypeDevice === 'GateEmulator') {
      status = 'Test terminal';
    }
    if (item.config.blocked) {
      status = 'Blocked';
    }

    return status;
  }

  updateKiosk(kiosk: any, sales: any) {
    const updateKioskRequest = this.postRequest(kiosk, {}, (data: ApiResponse) => data, 'saveSettings/', false, true);
    const updateSalesRequest = this.postRequest(sales, {}, (data: ApiResponse) => data, 'saveSales/', false, true);

    return forkJoin([updateKioskRequest, updateSalesRequest]);
  }

  deleteKiosk(kioskId: string) {
    this.postRequest({kioskId: kioskId}, {}, (data: ApiResponse) => {
      if (data.status) {
        this.stopHeartBeat().then(()=> {
          this.startHeartBeat();
        });
        this.appService.showToast('Готово!', 'Удаление прошло успешно', 'success');
      } else {
        this.appService.showToast('Ошибка', 'Удаление не удалось', 'danger')
      }
    }, 'removeTerminal/', false, true);
  }

  async updateKiosksVersions(postData) {
    this.commandsModelService.kiosksSelected = [];
    this.kiosksItems.forEach((kiosk)=> {
      kiosk.checked = false;
    });
    this.postRequest(postData, {}, (data: ApiResponse) => {
      if (data.status) {
        this.stopHeartBeat().then(()=> {
          this.startHeartBeat();
        });
        this.appService.showToast('Старт!', 'Запущен процесс обновления', 'success');
      } else {
        this.appService.showToast('Ошибка', 'Обновление не удалось', 'danger')
      }
    }, 'updateKiosks/', false, true);
  }

  // Функция для поиска киоска по его идентификатору
  findKioskById(kioskId: string) {
    //console.log('this.kiosksItems.', this.kiosksItems);
    return this.kiosksItems.find(kiosk => kiosk.id.toString() === kioskId);
  }
}
